import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { HashDisplay } from "./index.js"; // FIXME: circular deps
import { AdminTwinClient, TwinClient } from "../lib/client";

export function AddressCard({ h, state }) {
  if (!state) {
    state = useState(false);
  }

  let { data: twinUrl } = useQuery({
    queryKey: ["lookup", h],
    queryFn: async function lookupQFN() {
      let adminClient = new AdminTwinClient({url:"https://admin.tracker-demo.biz.todaq.net"});
      return await adminClient.lookup(h);
    },
    staleTime: Infinity
  });

  let { data: address } = useQuery({
    queryKey: ["info", twinUrl],
    queryFn: async function infoQFN() {
      if (!twinUrl) return null;
      let { address } = await TwinClient.test(twinUrl);
      return address;
    },
    retry: false,
    staleTime: Infinity
  });

  let { data: persona } = useQuery({
    queryKey: ["persona", twinUrl, h],
    queryFn: async function personaQFN() {
      if (!twinUrl) return null;
      let res = await TwinClient.persona(twinUrl);
      let info = res?.instances[0]?.info;
      try {
        return JSON.parse(info);
      } catch (_) {
        return info;
      }
    },
    retry: false,
    staleTime: Infinity
  });

  function AddressDisplay({ h }) {
    return <div className="rounded flex justify-end ~max-w-fit">
      {persona ?
        <div className="text-nowrap">
          {typeof persona === "string" ?
            <div>{persona}</div>
            :
            <>
              <div >{persona?.name}</div>
              <div className="text-xs float-left">{persona?.email}</div>
            </>
          }
        </div>
        :
        <div className="flex flex-row group/address" >
          <HashDisplay h={address || h} fontSize={"14px"} />
        </div>
      }
    </div>
  }

  return <AddressDisplay h={h} />;
}

import { useCallback, useEffect, useRef } from "react";

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function selectHashes(h) {
  return document.querySelectorAll(`[title="${h}"]`)
}

export function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function bytesTob64(arraybuffer) {
  // This is SOOOOO stupid; there must be a better way in 2024...
  if (!arraybuffer) return "";
  let bytes = new Uint8Array(arraybuffer);
  let str = "";
  for (let i=0; i < bytes.length; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return window.btoa(str)
}

export function jsonTryParse(str) {
  try {
    return JSON.parse(str)
  } catch (_) {
    return str;
  }
}

export function useClickOutside(callback, ref) {

  // Based on:
  // - https://stackoverflow.com/a/54292872
  // - https://www.youtube.com/watch?v=J-g9ZJha8FE&feature=youtu.be&t=481

  let handleClickOutside = useCallback((e) => {
    if (!ref?.current?.contains(e.target) && callback) {
      callback(e);
    }
  }, [ref]);

  useEffect(() => {
    if (ref?.current) {
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      }
    }
  }, [ref.current, handleClickOutside]);

  return ref;
}

export function useEscapeKey(callback) {
  let handleEscKey = useCallback((event) => {
    if (event.key == "Escape") {
      callback(event);
    }
  }, [callback]);

  useEffect(() => {
    document.addEventListener("keyup", handleEscKey, false);
    return () => {
      document.removeEventListener("keyup", handleEscKey, false);
    };
  }, [handleEscKey]);
}

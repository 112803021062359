
export function validateTwinUrl(twinUrl) {
  try {
    new URL(twinUrl);
    let [schema, rest] = twinUrl.split("https://");
    if (schema != "") {
      return { error: "Must start with https://" };
    }
    let [sub, domain] = rest.split(".biz.todaq.net");
    // let [sub, domain] = rest.split(".biz-staging.todaq.net");
    if (domain != "") {
      return { error: "Must end with .biz.todaq.net" };
    }
    let [addr, acc] = sub.split(".");
    if (acc.length == 0) {
      return { error: "Must have account slug"}
    }
    if (addr.length != 32) {
      return { error: "Address must be 32 characters long" };
    }
    let hexMatch = addr.match(/^[0-9a-f]+$/i)[0];
    if (hexMatch != addr) {
      return { error: "Address must string must be hex" };
    }

    return null;
  } catch (e) {
    return { error: "error", data: e };
  }
}

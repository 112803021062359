import { useNavigate } from "react-router-dom";
import { ProfileManager } from "./ProfileManager";
import { useProfiles } from "../lib/profiles";

export function Header() {
  let navigate = useNavigate();
  return <div className="flex flex-col p-2 mb-4">
    <div className="flex flex-row justify-between w-full">
      <div className="h-[2rem] aspect-logo">
        <img className="cursor-pointer" src="/img/logo.png" onClick={() => navigate("/home")} />
      </div>
      <div className="text-[1.5rem] text-zinc-400 font-bold ml-3">
        Asset Tracker
      </div>
      <ProfileManager />
    </div>
  </div>;
}

import { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { useProfiles } from "../lib/profiles.js"

export default function Logout() {
  let {updateProfile,setActiveProfile, active} = useProfiles();
  useEffect(() => {
    if (!active) return
    updateProfile(active, cfg => {
      let url = cfg.client?.url;
      return { ...cfg, client: { url }};
    })
    setActiveProfile(null)
  }, [active] )
  return <>
    {!active ? <Navigate to="/home"/> : "Logging out"}
  </>
}

import { useMemo } from "react";
import { useProfiles } from "../profiles.js";
import { TwinClient } from "./client.js";

export function getClient({client} = {}) {
  if (client) {
    return new TwinClient(client);
  }
}

export function getActiveClient() {
  const { getActiveProfile } = useProfiles();
  return getClient(getActiveProfile())
}

/**
 *
 * @returns {TwinClient}
 */
export function useActiveClient() {
  let { active, getActiveProfile } = useProfiles();
  let client = useMemo(() => getClient(getActiveProfile()), [active]);
  return client;
}

import { Abject } from "./todaadot/src/abject/abject.js";
import { Actionable } from "./todaadot/src/abject/actionable.js";
import { Hash } from "./todaadot/src/core/hash.js";
import { Twist } from "./todaadot/src/core/twist.js";
import { jsonTryParse } from "../util.js";

// following imports are required for parsing abject from twist
import { SimpleRigged } from "./todaadot/src/abject/actionable.js";
import { DQ } from "./todaadot/src/abject/quantity.js";
export function parseBytes(bytes) {
  let r = {};
  try {
    let current = Twist.fromBytes(bytes);
    r.current = current;

    let first = current.first();
    r.first = first;

    let abject = Abject.fromTwist(first);
    if (!abject) return r; // if abject not parsable, no point going forward
    r.abject = abject;

    let atoms = current.getAtoms();
    let history = current.knownHistory().map(h => new Twist(atoms, h))
    r.history = history;

    let di = abject.getContext();
    r.di = di;

  } catch (e) {
    console.warn("Encountered error(s) parsing file bytes", e);
    return { _error: e, ...r };
  }

  return r;
}

export function ssrFromContext(di) {
  const symTimestamp = Abject.gensym("ssr/timestamp");
  const symContent = Abject.gensym("ssr/content");
  return {
    timestamp: di.getFieldAbject(symTimestamp),
    content: jsonTryParse(di.getFieldAbject(symContent))
  };
}

/**
 *
 * @param {Twist} tw
 * @returns {Date}
 */
export function getTetherTs(tw) {
  const tsSym = Abject.gensym("field/relay/ts");
  let tether = tw.tether();
  if (tether) {
    let abj = Abject.fromTwist(tether);
    if (abj) {
      return abj.getFieldAbject(tsSym);
    }
  }
}

/**
 * @param {Actionable} di
*/
export function dqFromContext(di) {
  const symQuantity = Hash.fromHex("229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da37");
  const symDisplayPrecision = Hash.fromHex("2248a88ced3cb2ee7e8187fccc4d70dad8ec75bb8f01b5dbfcdf94ef0ce4fcaea4");
  const symMintingInfo = Hash.fromHex("220b0bfdd07d701255a52dff626c4a69b7af73e42061857b4b04537542c4e4ea52");

  return {
    quantity: di.getFieldAbject(symQuantity),
    displayPrecision: di.getFieldAbject(symDisplayPrecision),
    content: jsonTryParse(di.getFieldAbject(symMintingInfo)) // this is minting info
  };
}

import { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { bytesToHex, hexToBytes, useActiveClient } from "../lib/client";
import { HashDisplay, Header, PageTitle } from "../Components";
import { ButtonOk } from "../Components/Buttons.js";
import { dqFromContext, parseBytes, ssrFromContext } from "../lib/abject.js";
import { DQ } from "../lib/todaadot/src/abject/quantity.js";
import { SimpleRigged } from "../lib/todaadot/src/abject/actionable.js";

function InvRecord({hash}) {
  let navigate = useNavigate();
  let twinClient = useActiveClient();
  // --- this code is the same as in Asset.js ----
  let { data: fileBytes } = useQuery({
    queryKey: [ "fetch", hash ],
    queryFn: async function fetchFileQuery() {
      return await twinClient.fetch(hash)
        .then(buff => bytesToHex(new Uint8Array(buff)));
    },
    staleTime: Infinity
  });

  let asset = useMemo(function parseFileBytes() {
    if (!fileBytes) return null;
    if (fileBytes.data) return null;
    let bytes = hexToBytes(fileBytes);
    let parsed = parseBytes(bytes);
    if (!parsed.abject) return null;

    let assetType, assetProps;
    if (parsed.abject instanceof DQ) {
      assetType = "Quant"
      assetProps = dqFromContext(parsed.di);
    } else if (parsed.abject instanceof SimpleRigged) {
      assetType = "Unique"
      assetProps = ssrFromContext(parsed.di)
    }

    return {
      ...parsed,
      type: assetType,
      ... assetProps
    };

  }, [fileBytes]);

  if (!asset) return <></>
  return <li  className="p-4 text-sm">
    <div onClick={() => navigate(`/asset/${hash}`)} className="cursor-pointer flex-row justify-between w-full  hover:underline">
      <p className="flex-1 font-mono font-semibold">{asset.content?.name}</p>
      <HashDisplay h={hash} />
    </div>
  </li>;
}

export default function Inventory() {
  let navigate = useNavigate();
  let twinClient = useActiveClient()

  let { data: hashes } = useQuery({
    queryKey: ["GET", twinClient.url, "/toda"],
    placeholderData: [],
    queryFn: async function listFiles() {
      return await twinClient.listFiles();
    }
  });

  let { data: dqHashes} = useQuery({
    queryKey: ["GET", twinClient.url, "/dq"],
    placeholderData: [],
    queryFn: function getDq() {
      return twinClient.balance();
    }
  });

  let dqHashesSet = useMemo(() => new Set(dqHashes.map(dq => dq.files).reduce((arr, hs) => arr.concat(hs), [])),[dqHashes]);
  let assetHashes = useMemo(() => {
    if (!hashes || hashes.length == 0) return [];
    if(!dqHashesSet.size == 0) return hashes;
    return hashes.filter(h => !dqHashesSet.has(h)).sort();
  }, [dqHashesSet, hashes] )

  let fileInput = useRef(null);

  function createNewAsset(e) {
    navigate("/asset/create")
  }

  return <>
    <Header />
    <div className="py-10 px-2 flex flex-col items-center justify-center">
      <div className="p-6 w-full align-center">
        <PageTitle>Inventory</PageTitle>
        <ul className="~border-y-2 justify-center">
          {assetHashes && assetHashes.map(ass => <InvRecord key={ass} hash={ass} />)}
        </ul>
        <input type="file" ref={fileInput} hidden>
        </input>
        <div className="flex flex-col gap-6">
          <ButtonOk onClick={createNewAsset}>
            Create New Asset
          </ButtonOk>
          <ButtonOk onClick={() => navigate("/asset/validate")} >
            Validate Existing Asset
          </ButtonOk>
        </div>
      </div>
    </div>
  </>;
}

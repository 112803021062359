import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./Login.js";
import Logout from "./Logout.js";
import Asset from "./Asset.js";
import AssetDemo from "./AssetDemo.js";
import InvDemo from "./InvDemo.js";
import Inventory from "./Inventory.js";

import { useProfiles } from "../lib/profiles.js";
import CreateAsset from "./AssetCreate.js";
import { TransferAsset } from "./AssetTransfer.js";
import { ValidateAsset } from "./AssetValidate.js";
import { Signup } from "./Signup.js";

function WithActiveProfile({ children }) {
  let { active } = useProfiles();
  if (!active) {
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
}

export default function AppRoutes() {
  return <Routes>
    {/* demo routes -- delete me */}
    <Route path="/demo/inv" element={<InvDemo />} />
    <Route path="/demo/asset/:hash" element={<AssetDemo />} />

    <Route path="/" element={<Navigate to="/home"/>} />
    <Route path="/login" element={<Login />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/logout" element={<Logout/>} />{/* // This is going to be a button */}
    <Route path="/home" element={<Navigate to="/inventory" />} />
    <Route path="/inv" element={<Navigate to="/inventory"/>} />

    <Route path="/inventory" element={<WithActiveProfile><Inventory /></WithActiveProfile>} />
    <Route path="/asset/create" element={<WithActiveProfile><CreateAsset/></WithActiveProfile>}/>
    <Route path="/asset/validate" element={<ValidateAsset/>}/>
    <Route path="/asset/:hash" element={<WithActiveProfile><Asset /></WithActiveProfile> } />
    <Route path="/asset/:hash/transfer" element={<WithActiveProfile><TransferAsset/></WithActiveProfile>}/>

  </Routes>
}

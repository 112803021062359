import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query";
import { bytesToHex, hexToBytes, useActiveClient } from "../lib/client";
import { Hash, HashDisplay, Header, PageTitle, Tuple } from "../Components";
import { ButtonCancel, ButtonOk } from "../Components/Buttons.js";
import { Spinner } from "../Components/Spinner.js";
import { b64toBlob } from "../util.js";
import { dqFromContext, getTetherTs, parseBytes, ssrFromContext } from "../lib/abject.js";
import { DQ } from "../lib/todaadot/src/abject/quantity.js";
import { SimpleRigged } from "../lib/todaadot/src/abject/actionable.js";


function toDTG(date) { // This format barely readable; why not YYYY-MM-DD HH:MM:SS
  // Ensure the date is a Date object
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  // Day of the month with leading zero
  let day = String(date.getUTCDate()).padStart(2, '0');

  // Hours in 24-hour format with leading zero
  let hours = String(date.getUTCHours()).padStart(2, '0');

  // Minutes with leading zero
  let minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Month as three letter abbreviation
  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  let month = monthNames[date.getUTCMonth()];

  // Full year
  let year = date.getUTCFullYear();

  // Combine all parts into the DTG format
  return `${day}${hours}${minutes}Z${month}${year}`;
}

export function AssetComponent({client, asset}) {
  if (!asset || !asset.content) return <></>;
  asset.timestamp ??= asset.content.timestamp || getTetherTs(asset.first);
  let date = asset.timestamp ? toDTG(asset.timestamp) : "Unknown";
  let blob = b64toBlob(asset.content.content, asset.content?.fileMime);
  let mime = blob.type;
  let url = URL.createObjectURL(blob);

  return <>
    <div className="flex flex-col items-center justify-between">
      <div className="w-full align-center ">
        <PageTitle> {asset?.content?.name} ({asset.type}) </PageTitle>
        {client &&
          <div className="mb-5 h-5 bg-[url(/public/img/download-file.svg)] bg-no-repeat">
            <a  href={client.downloadLink(asset.current.getHash()).toString()}
                title="Download asset"
                className="pl-7 hover:underline text-zinc-100">
              Download asset
            </a>
          </div> }
        <Tuple position="0">Issuer ID <Hash h={asset.first.tether().getHash().toString()} /></Tuple>

        <Tuple position="1">Date {date}</Tuple>
        <Tuple position="2">Initial Owner ID <Hash h={asset.first.tether().getHash().toString()} /></Tuple>
        { asset.current.knownHistory().length > 2 &&
          <Tuple position="3">
            Prior owners
            <ul>{asset.history.slice(1,-1).map(h =>
              <li key={ h.tether().getHash().toString() } className="text-left"><Hash h={h.tether().getHash().toString()} /></li>)}
            </ul>
          </Tuple>}
        <Tuple position="4">Current owner <Hash h={asset.current.getTetherHash().toString()} /></Tuple>
        {/* // HAAAACK(sfertman): ^^ see issue #41 */}
        {asset.type == "Quant" ? <>
          <Tuple position="5">Amount {asset.quantity}</Tuple>
          <Tuple position="6">Units {asset.content.units}</Tuple>
          {/* <Tuple position="7">Decimal precision {asset.displayPrecision}</Tuple> */}
        </>: <></> }
        <Tuple position="8">Description {asset.content.description || "N/A"}</Tuple>
        <Tuple position="9">Asset Integrity Line <span>TODAQ</span></Tuple>
        <Tuple position="10">Instant Relay <span>TODAQ</span></Tuple>
        <div className="flex flex-col w-full gap-4 my-2 items-baseline">
        <p>Attached content</p>
        <div className="mb-5 h-5 bg-[url(/public/img/download-file.svg)] bg-no-repeat">
        <a href={url} download={asset.content?.fileName} title="Download asset"
           className="pl-7 hover:underline text-zinc-100">
          Download attached file
        </a>
        </div>
          <p className="w-full h-fit px-2 py-1 rounded bg-zinc-700 text-zinc-100 text-wrap break-words">
            {mime.startsWith('image') && <img src={url} /> }
          </p>
        </div>
      </div>
    </div>
  </>
}

export default function Asset() {
  let navigate = useNavigate();
  let { hash } = useParams();
  let twinClient = useActiveClient();
  let { isPending, isError, data: fileBytes, error } = useQuery({
    queryKey: ["fetch", hash],
    queryFn: async function fetchFileQuery() {
      return await twinClient.fetch(hash)
        .then(buff => bytesToHex(new Uint8Array(buff)));
    },
    staleTime: Infinity
  });

  let asset = useMemo(function parseFileBytes() {
    if (!fileBytes) return {};
    if (fileBytes.data) return {};
    let bytes = hexToBytes(fileBytes);
    let parsed = parseBytes(bytes);

    let assetType, assetProps;
    if (parsed.abject instanceof DQ) {
      assetType = "Quant"
      assetProps = dqFromContext(parsed.di);
    } else if (parsed.abject instanceof SimpleRigged) {
      assetType = "Unique"
      assetProps = ssrFromContext(parsed.di)
    }

    return {
      ...parsed,
      type: assetType,
      ... assetProps
    };

  }, [fileBytes]);

  if (isPending) {
    return <>
      <Header />
      <div className="flex gap-2">
        <Spinner className="!size-5"/>
        <div className="flex gap-2 text-info align-text-bottom">
          Retrieving asset <HashDisplay h={hash} />
        </div>
      </div>
    </>
  }

  if (!isPending && isError) {
    if (error?.data?.status == 404) {
      return <>
        <Header/>
        <div className="flex flex-col gap-6">
          <div className="bg-red-800 flex gap-1 justify-center" >
            Asset <HashDisplay h={hash}/> not found!
          </div>
          <ButtonCancel onClick={() => navigate(`/inventory`)}>
            Back to inventory
          </ButtonCancel>
        </div>
      </>
    }

    return <>
      <Header />
      <div className="flex flex-col gap-6">
        <div className="gap-6 bg-red-800">
          <div className="bg-red-800 text-center" >
            Error retrieving asset <Hash h={hash} />
          </div>
          <pre className="bg-red-800 mt-6 p-2">
            {JSON.stringify(error.data, null, 2)}
          </pre>
        </div>
        <ButtonCancel onClick={() => navigate(`/inventory`)}>
          Back to inventory
        </ButtonCancel>
      </div>
    </>
  }

  if (!asset) return <>
    <Header />
  </>

  return <>
    <Header />
    <AssetComponent client={twinClient} asset={asset} />
    <div className="fixed flex grow left-0 px-6 bottom-8 w-full flex-row justify-center">
      <ButtonCancel hidden={!!asset} onClick={() => navigate(`/inventory`)}>
        Back to inventory
      </ButtonCancel>
      <ButtonOk hidden={!asset} onClick={() => navigate(`/asset/${hash}/transfer`)}>
        Transfer
      </ButtonOk>
    </div>
  </>
}

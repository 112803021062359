export function Spinner({className, hidden, ...props}) {
  return <div className={ hidden ? "hidden" : [`
      size-full
      !p-0
      !m-0
      inline-block box-border
      border-[3.2px]
      border-solid
      rounded-full
      border-t-transparent
      border-r-current
      border-b-transparent
      border-l-current
      animate-spin`, className].join(" ")}
    {...props} >
  </div>;
}

export async function withSpinner(setIsSpinning, fn) {
  setIsSpinning(true);
  return Promise.resolve(fn()).finally(_ => setIsSpinning(false));
}

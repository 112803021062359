import { useRef } from "react";
import { Hash, Header } from "../Components";

function InvRecord({hash}) {
  return <li className="[&:not(:last-child)]:border-b p-4 font-mono items-center text-sm">
    <a href={`/asset/${hash}`} className="hover:underline ~bg-green-700"><Hash h={hash}/></a>
    <div className="flex flex-row text-xs justify-between">
      <div className="~border">type: <Hash h="229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da37"/></div>
      <div className="~border">created: 5days ago</div>
    </div>
  </li>;
}



export default function InvDemo() {

  let fileInput = useRef(null);

  function uploadFileFn(e) {
    let input = fileInput.current
    // input.classList.replace("hidden", "block");
    fileInput.current.click()

  }

  const inv = [
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da30",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da31",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da32",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da33",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da34",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da35",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da36",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da37",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da38",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da39",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da38",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da39",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da38",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da39",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da38",
    "229cd0e35e7f233a1c03f620f7c5024baf35c229df81ad613c622996bc1dc4da39",
  ]
  return <>
    <Header />
    <div className="my-4 text-center ~text-lg font-semibold"><p>INVENTORY</p><p>(simulated data)</p></div>
    <ul className="~border-y-2">
      {inv.map(ass => <InvRecord key={ass} hash={ass}/>)}
    </ul>
    <input type="file" ref={fileInput} hidden>
    </input>
    <button
      onClick={uploadFileFn}
      className="fixed bottom-8 right-8 bg-green-600  w-16 aspect-square rounded-full ~text-green-900 text-3xl">
      ＋
    </button>
  </>;
}

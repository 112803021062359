

/**
 *
 * @param {Object} param0
 * @param {string} param0.className
 * @returns
 */
export function ButtonOk({className, children, ...props}) {
  let css = "text-white disabled:text-gray-600 shadow border-2 border-gray-500 bg-gray-500 enabled:hover:bg-gray-600 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-5 py-2 text-center";
  return <button className={[css, className].join(" ")} {...props}>
    {children}
  </button>
}

/**
 *
 * @param {Object} param0
 * @param {string} param0.className
 * @returns
 */
export function ButtonCancel({className, children, ...props}) {
  let css = `px-5 py-2
             border-2 rounded-md
             text-sm font-medium text-center
             border-gray-500
             focus:ring-2 focus:outline-none focus:ring-blue-300
             enabled:hover:bg-gray-200
             text-gray-600
             disabled:text-gray-700
             disabled:border-gray-700
             enabled:hover:bg-gray-500
             enabled:hover:text-gray-800`;
  return <button className={[css, className].join(" ")}
                 {...props}>
    {children}
  </button>
}

/**
 *
 * @param {Object} param0
 * @param {*} param0.value value to write to clipboard
 * @param {string} param0.title
 * @param {string} param0.className
 * @returns
 */
export function CopyButton({value, title, className}) {
  return <button title={title}
      type="button"
      onClick={() => navigator.clipboard.writeText(value)}
      className={["bg-[url(/public/img/copy.svg)] bg-no-repeat",className].join(" ")} >
    </button>
}

import { useParams } from "react-router-dom"
import { Header, PageTitle } from "../Components";

export default function AssetDemo() {
  let { hash } = useParams();

  function Tuple({children}) {
    return <div className="flex flex-row w-full">
      <div className="w-fit text-nowrap whitespace-nowrap float-left">{children[0]}</div>
      <div className="w-full text-right float-right">{children[1]}</div>
    </div>
  }

  function Verified() {
    return <div className="text-green-600 text-right">Verified</div>
  }
  // --- this will come from backend ---
  let issuerId = "Shalina HealthCare"
  let date = "2023-02-26 09:30:42"
  let priorOwners = [
    "DHL Global Shipping, Mumbai",
    "DHL Global Shipping, Nairobi",
    "Kenya Wholesale Drugs",
  ];
  let description = "Bill of Lading for Malarone"
  let currentOwner = "MalibuPharmacy Nairobi"
  let embeddedQuantity = 20
  let quantityUnit = 10
  let assetIntegrityLine = "TODAQ"
  let instantRelay = "TODAQ Kenya"
  // ^^^ this will come from backend ^^^

  return <>
    <Header />
    <PageTitle>VERIFICATION REPORT DRUG BILL OF LADING</PageTitle>
    <div className="flex flex-col text-sm ">
      <div className="flex flex-row w-full gap-4 my-2 ">
        <Tuple>Asset ID <span className="font-mono">{hash.slice(0,8)}...{hash.slice(-8)}</span></Tuple>
        <Verified/>
      </div>
      <div className="flex flex-row w-full gap-4 my-2">
        <Tuple>Issuer ID {issuerId}</Tuple>
        <Verified />
      </div>
      <div className="flex flex-row w-full gap-4 my-2">
        <Tuple>Date {date}</Tuple>
        <Verified />
      </div>
      <div className="flex flex-row w-full gap-4 my-2">
        <Tuple>Initial Owner ID{issuerId}</Tuple>
        <Verified />
      </div>
      <div className="flex flex-row w-full gap-4 my-2">
        <Tuple>Prior owners {priorOwners}</Tuple>
        <Verified />
      </div>
      <div className="flex flex-row w-full gap-4 my-2">
        <Tuple>Current owner {currentOwner}</Tuple>
        <Verified />
      </div>
      <div className="flex flex-row w-full gap-4 my-2">
        <Tuple>Description {description}</Tuple>
        <Verified />
      </div>
      <div className="flex flex-row w-full gap-4 my-2">
        <Tuple>Embedded Quantity {embeddedQuantity}</Tuple>
        <Verified />
      </div>
      <div className="flex flex-row w-full gap-4 my-2">
        <Tuple>Quantity Unit {quantityUnit}</Tuple>
        <Verified />
      </div>
      <div className="flex flex-row w-full gap-4 my-2">
        <Tuple>Asset Integrity Line {assetIntegrityLine}</Tuple>
        <Verified />
      </div>
      <div className="flex flex-row w-full gap-4 my-2">
        <Tuple>Instant Relay {instantRelay}</Tuple>
        <Verified />
      </div>
    </div>
  </>
}

import { createContext, useContext, useEffect, useState } from "react";

export const ProfilesContext = createContext();

export function ProfilesContextProvider (props) {

  const [profiles, setProfiles] = useState(() => {
    const stored = localStorage.getItem("profiles");
    return stored ? JSON.parse(stored) : {};
  });

  useEffect(() => {
    localStorage.setItem("profiles", JSON.stringify(profiles))
  }, [profiles]);

  function listProfiles() {
    return Object.keys(profiles).filter(k => !k.startsWith("_"));
  }

  function getProfile(key) {
    return profiles[key];
  }

  function getProfiles() {
    return Object.fromEntries(Object.entries(profiles).filter(([k,_]) => !k.startsWith("_")));
  }

  function setProfile(key, profile) {
    return setProfiles(oldval => ({ ...oldval, [key]: profile }));
  }

  function updateProfile(key, f) {
    return setProfile(key, f(getProfile(key)))
  }

  function activeProfile() {
    return profiles._active;
  }

  function getActiveProfile() {
    return getProfile(activeProfile());
  }

  function setActiveProfile(key) {
    return setProfiles(oldval => ({ ...oldval, _active: key }));
  }

  function removeProfile(key) {
    return setProfiles(oldval => {
      const {[key]:_, ...rest } = oldval;
      return rest;
    });
  }

  return <ProfilesContext.Provider value={{
    listProfiles, getProfile, getProfiles, setProfile, updateProfile, removeProfile,
    active: profiles._active, activeProfile, getActiveProfile, setActiveProfile
  }}>
    {props.children}
  </ProfilesContext.Provider>
}

export function useProfiles() {
  return useContext(ProfilesContext);
}

import "./index.css"
import React from "react"
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { get, set, del } from 'idb-keyval';
import App from "./App";
import { ProfilesContextProvider } from "./lib/profiles";

// handle dark mode
if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
  document.documentElement.classList.add('dark')
} else {
  document.documentElement.classList.remove('dark')
}

function createIDBPersister(idbValidKey = "reactQueryPersistentCache") {
  return {
    persistClient: async function(client) {
      await set(idbValidKey, client);
    },
    restoreClient: async function() {
      return await get(idbValidKey);
    },
    removeClient: async function() {
      await del(idbValidKey);
    }
  };
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const root = createRoot(document.getElementById("root"));
root.render(<React.StrictMode>
  <div className="flex justify-center">
    <BrowserRouter>
      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: createIDBPersister() }}>
        <ProfilesContextProvider>
          <App />
        </ProfilesContextProvider>
      </PersistQueryClientProvider>
    </BrowserRouter>
  </div>
</React.StrictMode>);

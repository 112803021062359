/**
 *
 * @param {object} param0
 * @param {string} param0.id
 * @param {string} param0.label
 * @returns
 */
export function TextInput({ id, label, ...props}) {
  return <div className="flex flex-col gap-2">
    <label className="block text-sm font-medium text-zinc-400" htmlFor={id}>{label}</label>
    <input
      id={id}
      className="w-full px-2 py-1 rounded text-sm bg-zinc-700 disabled:text-zinc-400  text-zinc-100 invalid:text-error "
      {...props}/>
  </div>
}
